import React, { useCallback, useEffect, useState } from 'react';
import cn from 'clsx';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import axios from 'axios';

// import ContactUsContainer, { ContactUsModal } from './ContactUs.styled';

const ContactUs = ({ show, onDismiss, defaultSubject = '' }) => {
  const [input, setInput] = useState({
    subject: defaultSubject,
    email: '',
    message: '',
  });
  const [sending, setSending] = useState(false);
  const [privilegeSuccess, setPrivilegeSuccess] = useState(false);

  const inputHandler = useCallback(({ target: { name, value } }) => {
    setInput((state) => ({ ...state, [name]: value }));
  }, []);

  const submitHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (
        input.email.length > 0 &&
        isEmail(input.email) &&
        (defaultSubject !== 'privilege-plan'
          ? input.message.trim().length > 0
          : true)
      ) {
        setSending(true);

        (async () => {
          try {
            /* eslint-disable indent */
            await axios.post(
              `https://us-central1-rally-brucira.cloudfunctions.net/contact`,
              defaultSubject === 'privilege-plan'
                ? {
                    subject: 'privilege',
                    email: input.email,
                    message: input.plan || 'pro',
                  }
                : input,
            );
            /* eslint-enable indent */

            setInput({
              subject: '',
              email: '',
              message: '',
            });

            if (defaultSubject === 'privilege-plan') setPrivilegeSuccess(true);
            else onDismiss();
          } catch (error) {
            /* do nothing */
          } finally {
            setSending(false);
          }
        })();
      }
    },
    [defaultSubject, input],
  );

  const clickHandler = useCallback((e) => {
    if (e.target.id === 'contact-modal') {
      setInput({
        subject: '',
        email: '',
        message: '',
      });

      onDismiss();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('click', clickHandler);

    return () => window.removeEventListener('click', clickHandler);
  }, [clickHandler]);

  useEffect(() => {
    setInput({ ...input, subject: defaultSubject });
  }, [defaultSubject]);

  useEffect(() => {
    setPrivilegeSuccess(false);
    document.body.style.overflow = show ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);

  return (
    <div id="contact-modal" className={cn(['contact-us-modal', { show }])}>
      <div className="contact-us-container">
        <button
          type="button"
          aria-label="close"
          className="button-unstyled close-button"
          onClick={onDismiss}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="32"
              d="M368 368L144 144M368 144L144 368"
            />
          </svg>
        </button>
        <h3 style={{ marginTop: 0, marginBottom: 24, fontSize: 24 }}>
          {privilegeSuccess ? (
            <>Success!</>
          ) : (
            <>
              {defaultSubject === 'privilege-plan'
                ? 'Submit Details for Privilege Plan'
                : 'Contact Us'}
            </>
          )}
        </h3>
        {privilegeSuccess ? (
          <>
            <div>
              Thanks for your interest. We shall notify you once the Black
              Friday deals begin.
            </div>
          </>
        ) : (
          <form onSubmit={submitHandler} className="users-form smaller-labels">
            <div className="mb-2">
              <label htmlFor="contact-email">
                Email Address
                <br />
                <input
                  type="email"
                  name="email"
                  id="contact-email"
                  placeholder="Enter email address"
                  value={input.email}
                  onChange={inputHandler}
                  required
                />
              </label>
            </div>
            <div
              className="mb-2"
              style={
                defaultSubject === 'privilege-plan' ? { display: 'none' } : {}
              }>
              <label className="custom-select" htmlFor="contact-subject">
                Subject
                <br />
                <select
                  disabled={defaultSubject === 'billing'}
                  name="subject"
                  id="contact-subject"
                  value={input.subject}
                  onChange={inputHandler}>
                  {defaultSubject === 'privilege-plan' ? (
                    <>
                      <option value="privilege-plan">Black Friday</option>
                    </>
                  ) : (
                    <>
                      <option value="account-related">Account related</option>
                      <option value="upgrade-account">Upgrade account</option>
                      <option value="report-bug">Report a bug</option>
                      <option value="feature-request">
                        Recommend a new feature
                      </option>
                      <option value="billing">Billing</option>
                      <option value="sales">Sales</option>
                      <option value="report-abuse">Report abuse</option>
                      <option value="others">Something else</option>
                    </>
                  )}
                </select>
              </label>
            </div>
            {defaultSubject === 'privilege-plan' ? (
              <div className="mb-2">
                <label
                  className="custom-select"
                  htmlFor="contact-privilege-plan">
                  Select privilege plan
                  <br />
                  <select
                    name="plan"
                    id="contact-privilege-plan"
                    value={input.plan || 'pro'}
                    onChange={inputHandler}>
                    <option value="pro">Pro ($̶6̶0̶0̶ $150)</option>
                    <option value="team-1">Team - $̶1̶2̶0̶0̶ $300</option>
                    <option value="team-2">Enterprise - $̶2̶0̶0̶0̶ $500</option>
                  </select>
                </label>
              </div>
            ) : (
              <div className="mb-2">
                <label htmlFor="message">
                  How can we help you?
                  <br />
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Enter your message"
                    value={input.message}
                    onChange={inputHandler}
                    rows="5"
                    required
                    style={{ resize: 'none' }}
                  />
                </label>
              </div>
            )}
            <button
              type="submit"
              className="submit-button"
              style={{ padding: '14px 40px', fontSize: 16 }}
              id="chat-bot-submit"
              disabled={
                defaultSubject === 'privilege-plan'
                  ? sending === true ||
                    !input.email.length ||
                    !isEmail(input.email)
                  : sending === true ||
                    !input.message.trim().length ||
                    !input.email.length ||
                    !isEmail(input.email)
              }>
              {sending ? 'Sending...' : 'Submit'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

ContactUs.propTypes = {
  show: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  defaultSubject: PropTypes.string,
};

export default ContactUs;
